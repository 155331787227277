body,
#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica,
    'PingFang SC', 'Hiragino Sans GB', 'Source Han Sans', 'Microsoft YaHei', SimSun, sans-serif;
}

input,
button,
textarea,
select {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica,
    'PingFang SC', 'Hiragino Sans GB', 'Source Han Sans', 'Microsoft YaHei', SimSun, sans-serif;
}
